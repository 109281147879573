<template>
  <div class="popup detail daily-detail" @click="ctrlShow('cover')">
    <div class="frame" @click="$event.stopPropagation(), (showStatue = false), (modName = false)">
      <header>
        <div class="header-top">
          <div class="header-top-left" >
            <slot name="task-back"></slot>
          </div>
          <div class="header-top-right">
              
              <el-button class="file-button" v-if="summary.status !== 3" @click.stop="inFile" :style="'width:80px'">归档</el-button>
              <span class="ico-trash ico-span" @click.stop="delTask(id)" />
              <span class="ico-line ico-span" />
              <span class="close-ico ico-span" @click="ctrlShow('close')"></span>
          </div>
        </div>
        <div class="title">
          
          <span class="title-name" v-show="!modName" @click.stop="taskName()">{{ taskNameInput }}</span>
          <textarea
            @click.stop
            @keyup.enter="modTaskName"
            @focus="tnFocus"
            @input="autoTextarea"
            class="taskName"
            v-if="modName"
            v-model="newTaskName"
            ref="taskName"
          />
          
         
        </div>
      </header>
      <main>
        <div class="summary">
          <ul class="summary-ul">
            <li class="li1" @click.stop="showStatue = !showStatue">
              <img :src="xIco[summary.clean_status].ico" alt="" />
              <div v-show="showStatue" class="select">
                <ul>
                  <li
                    v-for="(item, index) in xIco"
                    :key="index"
                    @click="changStatus(index)"
                    :class="{ atStatus: index === parseInt(summary.clean_status) }"
                  >
                    <img :src="item.minIco" /><span>{{ item.str }}</span>
                  </li>
                </ul>
              </div>
              <div class="span">
                <span :style="{ color: xIco[summary.clean_status].color }" class="span-line">{{
                  xIco[summary.clean_status].str
                }}</span
                ><span class="span-line2">当前状态</span>
              </div>
            </li>
            <!-- <li class="li2" @click="$refs.stime.focus()">
              <div class="start-time" />
              <div class="bl1">
                <el-date-picker
                  style="opacity: 0"
                  ref="stime"
                  v-model="stime"
                  type="date"
                  placeholder="选择开始时间"
                  id="st"
                >
                </el-date-picker>
              </div>
              <div class="span">
                <span class="span-line">{{ stime1 }}</span
                ><span class="span-line2" :class="{ vertical: stime1 === '' }">开始时间</span>
              </div>
            </li>
            <li class="li3" @click="$refs.etime.focus()">
              <div class="end-time" />
              <div class="bl1">
                <el-date-picker
                  style="opacity: 0"
                  ref="etime"
                  v-model="etime"
                  type="date"
                  placeholder="选择结束时间"
                  id="et"
                >
                </el-date-picker>
              </div>
              <div class="span">
                <span class="span-line">{{ etime1 }}</span
                ><span class="span-line2" :class="{ vertical: etime1 === '' }">结束时间</span>
              </div>
            </li> -->
          </ul>
        </div>
        <nav>
          <ul>
            <li
              v-for="item in detailNav"
              :key="item.key"
              @click="handleClick(item)"
              @mouseleave="mouseleave($event, item)"
              @mouseenter="mouseenter($event, item)"
            >
              <div :class="{ dative: navKey === item.key }">
                <img :src="item.activeIco" width="18px" alt="yes" v-if="navKey === item.key" />
                <img :src="item.ico" width="18px" alt="" v-else />
                <span>{{ item.str }}</span>
                <span v-if="item.key !== 'information'">({{ item.sum }})</span>
              </div>
            </li>
          </ul>
        </nav>
        <keep-alive>
          <component
            :is="navKey"
            :name="summary.charge_name"
            :id="id"
            :cont="summary.clean_des"
            :taskTitle="summary.title"
            :level="summary.clean_level"
            @traClose="traClose($event)"
            @ordinary="ordinary($event)"
          >
          </component>
        </keep-alive>
        <operate :id="this.id" :record="record"></operate>
      </main>
      <!-- <footer></footer> -->
    </div>
  </div>
</template>

<script>
import information from '@/components/task/daily/detail/compoment/information';
import subtag from '@/components/task/daily/detail/compoment/subtag';
import enclosure from '@/components/task/daily/detail/compoment/enclosure';
import operate from '@/components/task/daily/detail/compoment/operate';
import { deleteDailyBox, postDailyBoxInfoLis, postDailyBoxInfoMod, postDailyBoxSubDel } from '@/service/help';
export default {
  name: 'detail',
  data() {
    return {
      modName: false,
      taskNameInput: '', //任务标题绑定
      newTaskName: '',
      titleIco: require('../../../../assets/image/icon-progress/icon_bj.png'),
      activeTitle: require('../../../../assets/image/icon-progress/icon_bj(1).png'),
      summary: {
        clean_dad: '', //父任务id
        clean_des: '', //描述
        clean_etime: '',
        clean_is_sub: '', //0为否，1为子
        clean_level: '', //紧急度
        clean_status: '0',
        clean_stime: '',
        ctime: '',
        id: '',
        is_deal: '', //0未选
        is_mytask: '',
        status: '', //四大类
        task_id: '-',
        title: '',
        uid: '',
        upload_sum: '',
        sub_sum: '',
      },
      showStatue: false, //改状态
      xIco: [
        {
          ico: require('../../../../assets/image/icon-progress/icon_wks@2x.png'),
          minIco: require('../../../../assets/image/icon-progress/icon_wks_16@2x.png'),
          str: '未开始',
          color: '#FF6057',
          bgColor: 'rgba(255,96,87,0.1)',
        },
        {
          ico: require('../../../../assets/image/icon-progress/icon_jxz@2x.png'),
          minIco: require('../../../../assets/image/icon-progress/icon_jxz_16@2x.png'),
          str: '进行中',
          color: '#FFC108',
          bgColor: 'rgba(255,193,8,0.1)',
        },
        {
          ico: require('../../../../assets/image/icon-progress/icon_ywc@2x.png'),
          minIco: require('../../../../assets/image/icon-progress/icon_ywc_16@2x.png'),
          str: '已完成',
          color: '#1EE48E',
          bgColor: 'rgba(30,228,142,0.1)',
        },
        {
          ico: require('../../../../assets/image/icon-progress/icon_gd.png'),
          minIco: require('../../../../assets/image/icon-progress/icon_gd@2x.png'),
          str: '归档',
          color: '#334681',
          bgColor: 'rgba(51,70,129,0.1)',
        },
      ],
      detailNav: [
        {
          key: 'information',
          ico: require('../../../../assets/image/icon-progress/icon_rwxi@2x.png'),
          activeIco: require('../../../../assets/image/icon-progress/icon_rwxi_h@2x.png'),
          str: '任务信息',
          sum: 0,
        },
        {
          key: 'subtag',
          ico: require('../../../../assets/image/icon-progress/icon_zrw@2x.png'),
          activeIco: require('../../../../assets/image/icon-progress/icon_zrw_h@2x.png'),
          str: '任务详情',
          sum: 0,
        },
        {
          key: 'enclosure',
          ico: require('../../../../assets/image/icon-progress/icon_fj@2x.png'),
          activeIco: require('../../../../assets/image/icon-progress/icon_fj_h@2x.png'),
          str: '附件',
          sum: 0,
        },
      ],
      navKey: '',
      status: [9, 1, 1],
      record: false, //被监听刷新操作轨迹
      stime: '',
      etime: '',
      stime1: '',
      etime1: '',
    };
  },
  props: ['id'],
  components: {
    information,
    subtag,
    enclosure,
    operate,
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      postDailyBoxInfoLis({ id: this.id }).then(res => {
        console.log(res.data);
        let data = res.data.data;
        this.summary = data;
        this.$store.commit('set', { type: 'taskInfo', data: this.summary });
        this.summary.clean_status = parseInt(this.summary.clean_status);
        this.taskNameInput = this.summary.title;
        this.stime1 = this.parseData(this.summary.clean_stime);
        this.etime1 = this.parseData(this.summary.clean_etime);
        this.detailNav[1].sum = this.summary.sub_sum;
        this.detailNav[2].sum = this.summary.upload_sum;
        if (data.clean_is_sub === '0') {
          this.$store.commit('setkv', { k: 'clean_dadTitle', v: data.title });
          this.$store.commit('setkv', { k: 'clean_dadId', v: data.id });
        } else {
          //屏蔽子任务
          this.detailNav.splice(1, 1);
        }
        this.handleClick(this.detailNav[0]);
        this.record = !this.record; //刷新轨迹
      });
    },
    delTask(id) {
      this.$confirm('确认要删除该这条任务吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(() => {
          if (this.summary.clean_is_sub === '0') {
            deleteDailyBox({ id: id.toString() }).then(() => {
              this.$emit('isShowDetail', 'daily');
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            });
          } else if (this.summary.clean_is_sub === '1') {
            postDailyBoxSubDel({ id: id.toString() }).then(() => {
              this.getData();
              this.$emit('ordinary', 'delSub');
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    ctrlShow(fromDom = 'cover') {
      if (fromDom === 'cover') {
        this.$emit('isShowDetail', 'dailyBack');
      } else if (fromDom === 'close') {
        this.$emit('isShowDetail', 'daily');
      }
    },
    traClose(id) {
      this.$emit('traClose', id, 'daily');
    },
    ordinary(name) {
      //子组件通知父组件通常入口
      this.record = !this.record; //改变操作轨迹
      switch (name) {
        case 'addSub': {
          this.detailNav.forEach(item => {
            if (item.key === 'subtag') {
              item.sum++;
            }
          });
          break;
        }
        case 'delSub': {
          this.detailNav.forEach(item => {
            if (item.key === 'subtag') {
              item.sum--;
            }
          });
          break;
        }
        case 'addSource': {
          this.detailNav.forEach(item => {
            if (item.key === 'enclosure') {
              item.sum++;
            }
          });
          break;
        }
        case 'delSource': {
          this.detailNav.forEach(item => {
            if (item.key === 'enclosure') {
              item.sum--;
            }
          });
          break;
        }
      }
    },

    handleClick(item) {
      this.navKey = item.key;
    },
    mouseenter(event, ico) {
      let target = event.target.firstChild.firstChild;
      target.setAttribute('src', ico.activeIco);
    },
    mouseleave(event, ico) {
      let target = event.target.firstChild.firstChild;
      if (target.getAttribute('alt') === 'yes') target.setAttribute('src', ico.activeIco);
      else target.setAttribute('src', ico.ico);
    },
    changStatus(index) {
      postDailyBoxInfoMod({
        key: 'clean_status',
        value: index.toString(),
        id: this.id,
      }).then(() => {
        this.record = !this.record; //刷新轨迹
        this.summary.clean_status = index;
      });
    },
    parseData(time) {
      if (time === '') return '';
      else return time.substr(5, 2) + '月' + time.substr(8, 2) + '日';
    },
    parseData1(data) {
      let y = data.getFullYear();
      let m = data.getMonth() + 1;
      let d = data.getDate();
      let h = data.getHours();
      let mi = data.getMinutes();
      let s = data.getSeconds();
      m = m < 10 ? '0' + m : m;
      d = d < 10 ? '0' + d : d;
      s = s < 10 ? '0' + s : s;
      return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s;
    },
    taskName() {
      this.modName = !this.modName;
      if (this.modName) {
        this.newTaskName = this.taskNameInput;
        setTimeout(() => {
          this.$refs.taskName.focus();
        }, 50);
      }
    },
    tnFocus(e) {
      let scrollHeight = e.target.scrollHeight;
      e.target.style.height = scrollHeight + 2 + 'px';
    },
    autoTextarea(e) {
      let scrollHeight = e.target.scrollHeight;
      e.target.style.height = scrollHeight + 2 + 'px';
      console.log(scrollHeight);
    },
    modTaskName() {
      this.newTaskName = this.newTaskName.replace('\n', '');
      postDailyBoxInfoMod({
        key: 'title',
        value: this.newTaskName,
        id: this.id.toString(),
      }).then(() => {
        this.modName = !this.modName;
        this.getData();
      });
    },
    inFile() {
      //归档方法
      if (this.summary.clean_status !== 2) {
        this.$alert('该任务不是已完成的任务', '归档错误', { confirmButtonText: '确定', center: true });
      } else {
        this.changStatus(3);
      }
    },
  },
  watch: {
    stime: {
      handler(newVal) {
        postDailyBoxInfoMod({
          key: 'clean_stime',
          value: this.parseData1(newVal),
          id: this.id,
        });
        this.stime1 = this.parseData(this.parseData1(newVal));
        this.record = !this.record; //刷新轨迹
      },
    },
    etime: {
      handler(newVal) {
        postDailyBoxInfoMod({
          key: 'clean_etime',
          value: this.parseData1(newVal),
          id: this.id,
        });
        this.etime1 = this.parseData(this.parseData1(newVal));
        this.record = !this.record; //刷新轨迹
      },
    },
  },
  computed: {
    xIcoList() {
      if (this.summary.clean_status === 2) {
        return this.xIco;
      } else {
        return this.xIco.slice(0, 3);
      }
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/detail.less';
</style>
