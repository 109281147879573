<template>
  <div class="information daily-information" @click="showLevel = false">
    <div class="belong">
      <div class="urgency">
        <div class="title">任务紧急度：</div>
        <div @click.stop="ctrlLevel">
          <img :src="levelIco[lev]" width="16px" v-if="!isNaN(lev)" alt="" /><button v-else class="type-span">
            选择紧急度</button
          ><span class="type-span">{{ levels[lev] }}</span>
        </div>
        <ul class="select-level" v-show="showLevel">
          <li v-for="(lIco, index) in levelIco" :key="index" @click="selectLevel(index)">
            <img :src="lIco" alt="" width="16px" /><span>{{ levels[index] }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="describe">
      <div class="title">描述：</div>
      <div class="editor-s" v-show="!editing">
        <!-- <p>{{ content }}</p> -->
        <div v-html="content" class="editor-main"></div>
        <div class="edit" @click="startEdit()">编辑</div>
      </div>
      <div class="editor" v-show="editing">
        <!-- <textarea type="text" placeholder="编辑任务描述" v-model="describe" ref="des"></textarea> -->
        <editor ref="editor" :editorData="describe" @setData="setDescribe" />

        <button class="ok" @click="okHandle">确定</button>
        <button class="cancel" @click="cancleHandle">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { postDailyBoxInfoMod } from '@/service/help';
import editor from '@/components/ckeditor/Editor';
import { tasklevel } from '@/common/js';
export default {
  name: 'information',
  data() {
    return {
      editing: false,
      levelIco: [
        require('../../../../../assets/image/icon-progress/icon_jjd_1@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_2@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_3@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_4@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_5@2x.png'),
        require('../../../../../assets/image/icon-progress/icon_jjd_6@2x.png'),
      ],
      describe: '',
      content: '',
      showLevel: false,
      lev: Number,
    };
  },
  props: ['id', 'cont', 'level'],
  components: {
    editor,
  },
  mounted() {
    this.content = this.cont;
    this.getData();
  },
  methods: {
    getData() {
      this.lev = parseInt(this.level);
    },
    setDescribe(val) {
      this.describe = val;
    },
    startEdit() {
      this.describe = this.content;
      this.editing = !this.editing;
    },
    okHandle() {
      // console.log(this.describe.length)
      let describe = this.describe;
      postDailyBoxInfoMod({
        id: this.id.toString(),
        key: 'clean_des',
        value: this.describe,
      }).then(res => {
        console.log(res.data.msg === '成功');
        if (res.data.msg === '成功') {
          this.content = describe;
        }
      });
      this.editing = !this.editing;
    },
    cancleHandle() {
      this.editing = !this.editing;
      this.describe = '';
    },
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
    ctrlLevel() {
      //打开紧急度列表
      this.showLevel = !this.showLevel;
    },
    selectLevel(index) {
      //选择改变紧急度
      postDailyBoxInfoMod({
        id: this.id.toString(),
        key: 'clean_level',
        value: index.toString(),
      }).then(() => {
        this.lev = index;
      });
    },
  },
  computed: {
    levels() {
      return tasklevel.map(v => {
        return v.value;
      });
    },
  },
  watch: {
    cont: {
      handler() {
        this.content = this.cont;
      },
    },
    level: {
      handler() {
        this.lev = parseInt(this.level);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/information.less';
</style>
