<template>
  <div class="subtag" @click="resetShow">
    <div class="sub-progress">
      <div class="num-progress">
        <span :style="{ color: xIco[2].color }">{{ status[2].num }}</span
        >/<span :style="{ color: xIco[1].color }">{{ status[1].num }}</span
        >/<span :style="{ color: xIco[0].color }">{{ status[0].num }}</span
        >/{{ subTask.length }}
      </div>
      <div class="graph-progress">
        <span class="status1" :style="{ width: status[2].percent }" v-show="status[2].num !== 0"></span>
        <span class="status2" :style="{ width: status[1].percent }" v-show="status[1].num !== 0"></span>
        <span class="status3" :style="{ width: status[0].percent }" v-show="status[0].num !== 0"></span>
      </div>
    </div>
    <div class="sub-task">
      <ul class="sub-ul">
        <li
          v-for="(item, index) in subTask"
          :key="index"
          class="sub-li"
          @contextmenu.prevent="delTask(item.id)"
          @click="$emit('traClose', item.id)"
        >
          <div
            class="ico"
            :style="{ backgroundColor: xIco[item.clean_status].bgColor, color: xIco[item.clean_status].color }"
            @click.stop="shiftStatus(index)"
          >
            <img width="16" :src="xIco[item.clean_status].ico" alt="" /><span>{{ xIco[item.clean_status].str }}</span>
          </div>
          <span class="sub-title">{{ item.title }}</span>
          <div class="ico-mod" @click.stop="taskNameMod(item, index)"></div>
          <div class="ico-del" @click.stop="delTask(item.id)"></div>
          <ul class="ctrlStatus" v-show="item.show">
            <li v-for="(status, ind) in xIco" :key="ind" @click.stop="changeStatus(index, ind, item.id)">
              <img
                :src="status.ico"
                alt=""
                width="
16px"
              /><span :style="{ color: status.color }">{{ status.str }}</span>
            </li>
          </ul>
          <div class="input-box" @click="$event.stopPropagation()" v-if="item.modNameShow">
            <el-input
              v-model="newTaskName"
              @blur="elInputBlur(index)"
              @focus="elInputFocus(index)"
              @keyup.enter.native="modSubTaskName(newTaskName, index)"
              :ref="'nameMod' + index"
            ></el-input>
          </div>
        </li>
      </ul>
      <div class="add-sub-task" @click.stop="showAddSubTaskInput()">
        <div class="add-sub-task-ico" />
        <span>添加子任务</span>
      </div>
      <div class="sub-task-input" v-show="addSubTaskShow">
        <input
          type="text"
          placeholder="添加子任务，按回车键（Enter）保存"
          v-model="subTitle"
          @keyup.enter="addSubTask"
          ref="subTaskAdd"
          @click="$event.stopPropagation()"
        />
        <div class="sub-etime" @click="$refs.sube.focus()" v-show="subEtime === ''"></div>
        <span @click="$refs.sube.focus()" class="select-etime" v-if="subEtime !== ''">{{
          parseData1(parseData(subEtime))
        }}</span>
        <div class="block1">
          <el-date-picker
            style="opacity: 0"
            ref="sube"
            id="sub-etime"
            v-model="subEtime"
            type="date"
            placeholder="选择结束时间"
            prefix-icon="prefix-icon"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  postDailyBoxSubLis,
  postDailyBoxSubNew,
  postDailyBoxSubDel,
  postDailyBoxInfoMod,
  postDailyBoxSubMod,
} from '@/service/help';
import { xIco } from '@/common/js';
export default {
  name: 'subtag',
  data() {
    return {
      subTask: [
        {
          clean_dad: '',
          clean_des: '',
          clean_etime: '',
          clean_is_sub: '',
          clean_level: '',
          clean_status: 0,
          clean_stime: '',
          ctime: '',
          id: '',
          is_deal: '',
          is_mytask: '',
          status: '',
          task_id: '',
          title: '',
          uid: '',
        },
      ],
      xIco,
      status: [
        {
          num: 0,
          percent: '',
        },
        {
          num: 0,
          percent: '',
        },
        {
          num: 0,
          percent: '',
        },
      ],
      addSubTaskShow: false,
      subEtime: '',
      subTitle: '',
      charge_id: '',
      charge_name: '',
      selectSubid: '',
      newTaskName: '',
    };
  },
  props: ['name', 'id'],
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      postDailyBoxSubLis({
        clean_dad: this.id.toString(),
      }).then(res => {
        let data = res.data;
        if (data === '') {
          this.subTask = [];
        } else {
          this.subTask = data.data;
        }
        this.subTask.forEach(item => {
          item.clean_status = parseInt(item.clean_status);
          item.id = parseInt(item.id);
          this.$set(item, 'show', false);
          this.$set(item, 'modNameShow', false);
        });
        this.percent();
      });
    },
    shiftStatus(index) {
      // this.resetShow();
      this.subTask[index].show = !this.subTask[index].show;
    },
    changeStatus(index, ind, id) {
      postDailyBoxInfoMod({
        key: 'clean_status',
        value: ind.toString(),
        id: id.toString(),
      }).then(() => {
        this.subTask[index].clean_status = ind;
        this.percent();
      });
      // this.shiftStatus(index);
      this.subTask[index].show = !this.subTask[index].show;
    },
    percent() {
      this.status[0].num = 0;
      this.status[1].num = 0;
      this.status[2].num = 0;
      for (let i in this.subTask) {
        if (this.subTask[i].clean_status === 0) {
          this.status[0].num++;
        } else if (this.subTask[i].clean_status === 1) {
          this.status[1].num++;
        } else {
          this.status[2].num++;
        }
      }
      let length = this.subTask.length;
      this.status[0].percent = (this.status[0].num / length) * 100 + '%';
      this.status[1].percent = (this.status[1].num / length) * 100 + '%';
      this.status[2].percent = (this.status[2].num / length) * 100 + '%';
    },
    resetShow() {
      this.subTask.forEach(item => {
        item.show = false;
        item.showMem = false;
      });
    },
    showAddSubTaskInput() {
      if (!this.addSubTaskShow) {
        this.addSubTaskShow = true;
        this.$nextTick(() => {
          this.$refs.subTaskAdd.focus();
        });
      } else {
        this.addSubTaskShow = false;
        this.subTitle = '';
      }
    },
    addSubTask() {
      /*      this.subTitle = '';*/
      postDailyBoxSubNew({
        title: this.subTitle,
        clean_dad: this.id.toString(),
        clean_etime: this.parseData(this.subEtime),
      }).then(() => {
        this.$emit('ordinary', 'addSub');
        this.getData();
        this.subTitle = '';
      });
      this.charge_id = '';
      this.subEtime = '';
      this.addSubTaskShow = false;
    },
    delTask(id) {
      this.$confirm('确认要删除该这条任务吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(() => {
          postDailyBoxSubDel({ id: id.toString() }).then(() => {
            this.getData();
            this.$emit('ordinary', 'delSub');
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    parseData(data) {
      if (typeof data === 'object') {
        let y = data.getFullYear();
        let m = data.getMonth() + 1;
        let d = data.getDate();
        let h = data.getHours();
        let mi = data.getMinutes();
        let s = data.getSeconds();
        m = m < 10 ? '0' + m : m;
        d = d < 10 ? '0' + d : d;
        h = h < 10 ? '0' + h : h;
        mi = mi < 10 ? '0' + mi : mi;
        s = s < 10 ? '0' + s : s;
        return y + '-' + m + '-' + d + ' ' + h + ':' + mi + ':' + s;
      } else return '';
    },
    parseData1(time) {
      console.log(time);
      if (time === '') return '';
      else return time.substr(5, 2) + '月' + time.substr(8, 2) + '日';
    },
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
    showById(id, name, taskName) {
      this.subTaskId = id;
      this.subCharge = name;
      this.subTaskName = taskName;
      this.showDetail = true;
    },
    taskNameMod(item, i) {
      this.resetShow();
      item.modNameShow = !item.modNameShow;
      if (item.modNameShow) {
        let n = 'nameMod' + i;
        this.$nextTick(() => {
          this.$refs[n][0].focus();
        });
      }
    },
    elInputFocus(i) {
      this.newTaskName = this.subTask[i].title;
      console.log('准备输入');
    },
    elInputBlur(i) {
      this.newTaskName = '';
      this.subTask[i].modNameShow = false;
    },
    modSubTaskName(n, i) {
      let id = this.subTask[i].id;
      console.log('修改任务名字为：' + n, id);
      let fd = new FormData();
      fd.append('key', 'title');
      fd.append('value', n);
      fd.append('task_id', id.toString());
      postDailyBoxSubMod(fd)
        .then(() => {
          this.subTask[i].title = n;
          this.elInputBlur(i);
        })
        .then(res => {
          console.log(res);
        });
    },
  },
  computed: {
    store() {
      return this.$store.state.dailyInfo;
    },
    xIcoList() {
      return function (n) {
        if (n === 2) {
          return this.xIco;
        } else {
          return this.xIco.slice(0, 3);
        }
      };
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/subtag';
/deep/.block1 {
  position: absolute;
  top: 10px;
  right: -40px;
  #sub-etime {
    padding: unset;
    width: 1px;
    pointer-events: none;
  }
}
</style>
