<template>
  <div class="enclosure daily-enclosure" @click.stop="resetShow">
    <div class="sum">
      <span
        >总{{ sum }}个附件
        <span class="add" @click.stop="showUpLoad = !showUpLoad"
          ><span class="add-ico" /><span>添加附件</span></span
        ></span
      >
      <span class="download" @click="downloadAll" v-show="sum > 1"
        ><span class="download-ico" /><span>全部下载</span></span
      >
      <ul class="upload" v-show="showUpLoad">
        <li @click="$refs.upload.click()">
          本地上传<input v-show="false" type="file" ref="upload" @change="changeFile($event)" />
        </li>
        <li>网盘上传</li>
      </ul>
    </div>
    <!--    <img :src="file" alt="aaa">-->
    <div class="source">
      <ul class="enclosure-ul">
        <!--        <li v-for="(item, index) in source" :key="index" @contextmenu.prevent="resetShow(), (item.show = true)">-->
        <li
          class="enclosure-li"
          v-for="(item, index) in source"
          :key="index"
          @mouseenter.stop="mouseenter(index)"
          @mouseleave.stop="mouseleave(index)"
        >
          <img v-image-preview width="110px" height="50px" :src="item.url" v-if="typeFile(item.filename) === 0" />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_w.png"
            v-else-if="typeFile(item.filename) === 1"
            @click="fileViewUrl = item.url"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_x.png"
            v-else-if="typeFile(item.filename) === 2"
            @click="fileViewUrl = item.url"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_p.png"
            v-else-if="typeFile(item.filename) === 3"
            @click="fileViewUrl = item.url"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_pdf.png"
            v-else-if="typeFile(item.filename) === 4"
            @click="fileViewUrl = item.url"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_ys.png"
            v-else-if="typeFile(item.filename) === 8"
          />
          <img
            width="110px"
            height="50px"
            src="../../../../../assets/image/icon-progress/pic_wz.png"
            v-else-if="typeFile(item.filename) === 999"
          />
          <span
            v-else
            style="width: 110px; height: 50px; display: inline-block; border: 1px solid #e8ecef; vertical-align: middle"
            >未知文件</span
          >
          <div class="source-details">
            <span>{{ item.filename }}</span
            ><br />
            <span class="size">{{ item.size }}来自{{ $store.state.account }}</span>
            <span class="data">{{ parseData(item.ctime) }}</span>
          </div>
          <div v-show="item.show" class="mouse-show">
            <ul class="delOrDown">
              <li @click="down(item.id, item.filename)"><div class="ico-xzfj" /></li>
              <!--下载-->
              <li @click="del(item.id)"><div class="ico-trash" /></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="fileViewFrame" v-if="fileViewUrl !== ''">
      <span class="close-ico" @click="fileViewUrl = ''"></span>
      <file-view :file-view-url="fileViewUrl" :type-file="typeFile(fileViewUrl)" @setFileUrlNull="fileViewUrl = ''" />
    </div>
  </div>
</template>

<script>
import {
  deleteDailyUploadDel,
  postDailyDownload,
  postDailyDownloadAll,
  postDailyUploadLis,
  postDailyUploadNew,
  uploadFile,
} from '@/service/help';
import { dataURLtoBlob, suffix, typeFile } from '@/utils/utils';
import fileView from '@/components/task/progress/detail/compoment/fileView';
import axios from 'axios';

export default {
  name: 'enclosure',
  data() {
    return {
      sum: 0,
      source: [
        {
          clean_id: '',
          ctime: '',
          filename: '',
          id: 0,
          size: '',
          uid: 0,
          url: '',
          show: false,
        },
      ],
      showUpLoad: false,
      file: '',
      downUrl: '',
      fileViewUrl: '',
    };
  },
  props: ['name', 'id', 'taskTitle'],
  components: {
    fileView,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      postDailyUploadLis({
        clean_id: this.id.toString(),
      }).then(res => {
        let data = res.data.data;
        if (data.length === 0) {
          this.source = [];
          this.sum = 0;
        } else {
          this.source = data;
          this.sum = this.source.length;
          this.source.forEach(item => {
            this.$set(item, 'show', false);
          });
        }
      });
    },
    changeFile(e) {
      let a = e.target.value;
      let name = a.slice(a.lastIndexOf('\\') + 1);
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      let _this = this;
      reader.onload = function (e) {
        //这里的this指向函数
        _this.file = this.result;
        let fd = new FormData();
        let blob = dataURLtoBlob(e.target.result); //转blob
        if (blob.size > 10733741824) {
          alert('所传文件不能大于1G');
          return false;
        }
        let f = new File([blob], name, { type: blob.type });
        fd.append('uploadfile', f); //数据
        let size = blob.size / 1024;
        if (size > 1024) {
          size = (size / 1024).toFixed(2) + 'm';
        } else {
          size = size.toFixed(2) + 'k';
        }
        // axios({
        //   url: 'https://up.eai9.com', //请求上传文件返回url
        //   method: 'post',
        //   data: fd,
        //   headers: {
        //     'content-type': 'multipart/form-data',
        //   },
        // })

        uploadFile(fd).then(res => {
          let url = res.data.data;
          postDailyUploadNew({
            //成功取到url传给后台
            url: url.toString(),
            size: size,
            clean_id: _this.id.toString(),
            filename: name.toString(),
          }).then(() => {
            _this.$emit('ordinary', 'addSource');
            _this.getData();
          });
        });
      };
      this.showUpLoad = false;
    },
    downloadAll() {
      postDailyDownloadAll({
        clean_id: this.id.toString(),
      }).then(res => {
        console.log(res);
      });
    },
    down(id, name) {
      postDailyDownload({
        id: id,
      }).then(res => {
        let url = res.data.data.url;
        this.downloadFile(url, name);
      });
    },
    del(id) {
      this.$confirm('确认要删除该附件吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      }).then(() => {
        deleteDailyUploadDel({
          id: id,
        }).then(() => {
          this.$emit('ordinary', 'delSource');
          this.getData();
        });
      })
    },
    resetShow() {
      this.source.forEach(item => {
        item.show = false;
      });
      this.showUpLoad = false;
    },
    parseData(time) {
      return time.substr(5, 2) + '月' + time.substr(8, 2) + '日' + time.substr(11, 5);
    },
    mouseenter(index) {
      this.$nextTick(() => {
        this.source[index].show = true;
      });
    },
    mouseleave(index) {
      this.$nextTick(() => {
        this.source[index].show = false;
      });
    },
    downloadFile(url, name) {
      if (typeFile(name) === 0) {
        //文件是图片的情况
        let type = suffix(name);
        this.ImgtodataURL(url, name, type);
        /*        this.exl(url);*/
      } else {
        //原本的下载方法
        try {
          let elemIF = document.createElement('a');
          elemIF.href = url;
          elemIF.style.display = 'none';
          elemIF.download = '';
          console.log(name);
          document.body.appendChild(elemIF);
          elemIF.click();
          document.body.removeChild(elemIF);
        } catch (e) {
          console.log(e);
        }
      }
    },

    typeFile(fileName) {
      //判断文件类型方法
      return typeFile(fileName);
    },
    exl(u) {
      const url = u;
      // window.open(_this.detail.imgUrl)
      let xmlhttp = new XMLHttpRequest();
      xmlhttp.open('GET', url, true);
      xmlhttp.responseType = 'blob';
      xmlhttp.onload = function () {
        if (this.status === 200) {
          const blob = this.response;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          // link.download = url.substring(url.lastIndexOf("/") + 1, url.length);
          link.download = '图片.' + url.substring(url.lastIndexOf('.') + 1, url.length);
          //此写法兼容可火狐浏览器
          document.body.appendChild(link);
          const evt = document.createEvent('MouseEvents');
          evt.initEvent('click', false, false);
          link.dispatchEvent(evt);
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
          console.log(link.download);
          console.log(link.substring);
        }
      };

      xmlhttp.send();
    },
    ImgtodataURL(url, filename, fileType) {
      this.getBase64(url, fileType, _baseUrl => {
        // 创建隐藏的可下载链接
        var eleLink = document.createElement('a');
        eleLink.download = filename;
        eleLink.style.display = 'none';
        // 图片转base64地址
        eleLink.href = _baseUrl;
        // 触发点击
        document.body.appendChild(eleLink);
        eleLink.click();
        // 然后移除
        document.body.removeChild(eleLink);
      });
    },
    getBase64(url, fileType, callback) {
      //通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片
      var Img = new Image(),
        dataURL = '';
      Img.onload = function () {
        //要先确保图片完整获取到，这是个异步事件
        var canvas = document.createElement('canvas'), //创建canvas元素
          width = Img.width, //确保canvas的尺寸和图片一样
          height = Img.height;
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中
        dataURL = canvas.toDataURL('image/' + fileType); //转换图片为dataURL
        callback ? callback(dataURL) : null;
      };
      Img.src = url;
      Img.setAttribute('crossOrigin', 'Anonymous');
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/enclosure';
.fileViewFrame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  .close-ico {
    float: right;
    margin-top: 40px;
    margin-right: 25px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('../../../../../assets/image/icon-progress/icon_gb.png');
  }
  .close-ico:hover {
    background-image: url('../../../../../assets/image/icon-progress/icon_gb_h.png');
  }
}
</style>
