<template>
  <div class="daily" @click="resetShow">
    <main class="daily-main">
      <div class="left-part">
        <header>
          <div
            class="left-nav"
            v-for="item in leftNav"
            :key="item.key"
            @click="leftKey = item.key"
            :class="{ active: item.key === leftKey }"
          >
            <div class="ico" :class="item.icoClass" />
            <span>{{ item.str }}</span>
          </div>
        </header>

        <main
          v-show="leftKey === 'storageBox'"
          class="storageBox"
          @drop="dailyDrop($event, '0')"
          @dragover="allowDrop($event)"
        >
          <div class="item-box add-new-task" v-show="isAddStorage" @click.stop="showStorageeEdit">
            <img src="../../../assets/image/icon-progress/icon_tjfj.png" alt="" />
            <span>添加新任务</span>
          </div>
          <div class="item-box add-new-task-edit" v-show="!isAddStorage" @click="$event.stopPropagation()">
            <textarea
              placeholder="任务标题"
              @keyup.enter="addDaily"
              v-model="dailyTasksTitle"
              ref="storageEdit"
            ></textarea>
            <button @click="addDaily">保存</button>
          </div>
          <div class="daily-tasks">
            <ul class="daily-tasks-ul">
              <li
                v-for="(item, index) in taskLiRe(dailyTasks[isInfileList])"
                :key="item.id"
                class="item-box"
                :class="{ res: resId.indexOf(item.id) !== -1 }"
                :id="item.id"
                @contextmenu.prevent="delDailyBox(item.id, item.task_id)"
                :draggable="isdraggable(item.clean_status)"
                @dragstart="drag($event, '0', item.task_id, index)"
                @click="displayDetail(item.id, item.task_id, item.title)"
              >
                <div
                  class="ico"
                  :style="{ backgroundColor: xIco[item.clean_status].bgColor, color: xIco[item.clean_status].color }"
                >
                  <img :src="xIco[item.clean_status].ico" alt="" width="14" /><span>{{
                    xIco[item.clean_status].str
                  }}</span>
                </div>
                <span class="daily-title">{{ item.title }}</span
                ><img class="gr-img" src="../../../assets/image/icon-daily/icon_gr_16.png" />
              </li>
            </ul>
          </div>
          <div class="null" />
        </main>

        <main
          v-show="leftKey === 'myTask'"
          class="myTask"
          @drop="myTaskDrop($event, '0')"
          @dragover="allowDrop($event)"
        >
          <ul>
            <li
              in
              task
              v-for="(item, index) in taskLiRe(myTask[isInfileList])"
              :key="item.id"
              :id="item.id"
              :class="{ res: resId.indexOf(item.id) !== -1 }"
              @click="showById(item.task_id)"
              v-show="item.sn !== ''"
              :draggable="isdraggable(item.clean_status)"
              @dragstart="drag($event, '0', item.task_id, index)"
            >
              <div
                class="ico"
                :style="{ backgroundColor: xIco[item.clean_status].bgColor, color: xIco[item.clean_status].color }"
              >
                <img :src="xIco[item.clean_status].ico" alt="" width="14" /><span>{{
                  xIco[item.clean_status].str
                }}</span>
              </div>
              <span class="daily-title">{{ item.title }}</span
              ><img src="../../../assets/image/icon-daily/icon_sjx_16.png" class="gr-img"/>
            </li>
          </ul>
        </main>
        <div class="isInfile">
          <span :style="{ color: isInfileList === '已归档' ? '#13BFFF' : '#334681' }" @click="isInfileList = '已归档'"
            >已归档</span
          >|<span @click="isInfileList = '进行中'" :style="{ color: isInfileList === '进行中' ? '#13BFFF' : '#334681' }"
            >进行中</span
          >
        </div>
      </div>
      <div class="right-part">
        <header>
          <div class="search">
            <input type="tel" class="search-input" placeholder="搜索" v-model="keyword" @keyup.enter="search" />
          </div>
        </header>
        <main>
          <ul class="tbl-ul">
            <li v-for="item in tasksByLevel" :key="item.key" :class="item.cla" class="tbl-li">
              <div class="title">
                <span @click.stop="levelSpanHandle(item.status)" v-show="levelStatus !== item.status">{{
                  item.level
                }}</span>
                <el-input
                  v-model="levelName"
                  :ref="item.status + 'levmod'"
                  @keyup.enter.native="levelNameMod(item.status)"
                  @blur="levelStatus = ''"
                  placeholder="按enter修改"
                  v-show="levelStatus === item.status"
                  class="level-mod-input"
                ></el-input>
                <div class="icon-infile" @click="taskListInFile(item.status)" />
                <img
                  class="plus"
                  src="../../../assets/image/icon-progress/icon_plus@2x.png"
                  width="20"
                  @click="ulScrollToCtrl(item.status)"
                />
              </div>
              <ul class="tasks" @dragover="allowDrop($event)" @drop="drop($event, item.status)" :ref="item.status">
                <div class="plus-box" v-show="plusStatus === item.status">
                  <textarea
                    placeholder="请输入内容"
                    v-model="dailyTitle"
                    @keyup.enter="plus(item.status)"
                    :ref="item.status + 'are'"
                  />
                  <button class="ok" @click="plus(item.status)">创建</button>
                  <button class="cancel" @click="plusStatus = ''">取消</button>
                </div>
                <li
                  v-for="(task, index) in taskLiRe(item.task)"
                  :key="task.id"
                  :id="task.id"
                  :class="{ res: resId.indexOf(task.id) !== -1 }"
                  draggable="true"
                  @dragstart="drag($event, item.status, task.task_id, index)"
                  @drop.stop="drop($event, item.status, index)"
                  @contextmenu.prevent="delDailyBox(task.id, task.task_id)"
                  @click="displayDetail(task.id, task.task_id, task.title)"
                >
                  <input
                    type="checkbox"
                    name="task"
                    autocomplate="off"
                    v-model="task.is_deal"
                    @click.stop="infile(task.id)"
                  />
                  <span>{{ task.title }}</span>
                  <img v-if="task.task_id === '-1'" class="ico" src="../../../assets/image/icon-daily/icon_gr_16.png" />
                  <img v-else class="ico" src="../../../assets/image/icon-daily/icon_sjx_16.png" />
                  <div class="background-change" />
                </li>
              </ul>
            </li>
          </ul>
        </main>
      </div>
    </main>
    <detail v-if="showDetail" @isShowDetail="isShowDetail($event)" @traClose="traDaily(arguments)" :id="taskId"></detail>
    <daily-detail
      v-if="showDailyDetail"
      @isShowDetail="isShowDetail($event)"
      @traClose="traDaily(arguments)"
      :id="dailyTaskId"
    >
      <template slot="task-back" v-if="traId.length>1">
        <div class="back-master-task"   @click="isShowDetail('dailyBack')">
              <i class="el-icon-arrow-left"></i>
              <span class="back-text">返回</span>
        </div>
        <div class="task-head-title">| 任务</div>
      </template>
    </daily-detail>
  </div>
</template>

<script>
import {
  getDailyBoxLis,
  postDailyBoxNew,
  deleteDailyBox,
  postDailyStatusLis,
  postDailyBoxMod,
  getDailyMyself,
  postDailySearch,
  postDailyDeal,
  postDailyReturnfile,
  postDailyStatusNameMod,
  postDailyStatusRank,
} from '@/service/help';
import detail from '@/components/task/progress/detail/detail';
import dailyDetail from '@/components/task/daily/detail/dailyDetail';
import { xIco, dailyTaskType } from '@/common/js/index';
export default {
  name: 'Daily',
  data() {
    return {
      leftNav: [
        {
          key: 'storageBox',
          str: '个人收纳箱',
          icoClass: 'box',
        },
        {
          key: 'myTask',
          str: '我参与的任务',
          icoClass: 'my',
        },
      ],
      leftKey: 'storageBox',
      tasksByLevel: dailyTaskType,
      myTask: {
        已归档: [
          {
            id: 0,
            clean_status: 0,
            title: '',
            task_id: '',
          },
        ],
        进行中: [
          {
            id: 0,
            clean_status: 0,
            title: '',
            task_id: '',
          },
        ],
      },
      xIco,
      dailyTasks: {
        已归档: [{ title: '', id: '', task_id: '', clean_status: 0 }],
        进行中: [{ title: '', id: '', task_id: '', clean_status: 0 }],
      },
      keyword: '',
      dailyTitle: '', //日事清输入框v-model
      dailyTasksTitle: '', //四大类输入框v-model
      isAddStorage: true, //输入框添加任务
      taskId: Number, //跟任务进度管理相关
      showDetail: false,
      dailyTaskId: Number, //日事清任务相关
      showDailyDetail: false,
      plusStatus: '',
      resId: [], //搜索的id
      traId: [], //记录套娃id
      isInfileList: '进行中',
      levelStatus: '', //控制改变四个列表名字
      levelName: '',
    };
  },
  components: {
    detail,
    dailyDetail,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getDailyBoxLis().then(res => {
        //收纳箱
        this.dailyTasks = res.data.data;
      });
      getDailyMyself().then(res => {
        //我的任务
        this.myTask = res.data.data;
      });
      this.byStatus(1);
      this.byStatus(2);
      this.byStatus(3);
      this.byStatus(4);
    },
    byStatus(int, callback) {
      postDailyStatusLis({ status: int.toString() }).then(res => {
        this.tasksByLevel[int - 1].level = res.data.data.name;
        this.tasksByLevel[int - 1].task = res.data.data.lis;
        this.tasksByLevel[int - 1].task.forEach(item => {
          if (item.is_deal === '0') {
            item.is_deal = false;
          } else {
            item.is_deal = true;
          }
        });
        callback ? callback() : {};
      });
    },
    resetShow() {
      this.isAddStorage = true;
    },
    ulScrollToCtrl(status) {
      //根据状态类别选择输入框
      this.plusStatus = status;
      this.$refs[status][0].scrollTo(0, 0);
      this.$nextTick(() => {
        this.$refs[status + 'are'][0].focus();
      });
    },
    showStorageeEdit() {
      this.isAddStorage = false;
      this.$nextTick(() => {
        this.$refs.storageEdit.focus();
      });
    },
    addDaily() {
      postDailyBoxNew({ title: this.dailyTasksTitle, status: '0' })
        .then(res => {
          console.log(res);
          getDailyBoxLis().then(res => {
            this.dailyTasks = res.data.data;
          });
          this.dailyTasksTitle = '';
        })
        .catch(e => {
          alert(e);
        });
    },
    plus(s) {
      this.dailyTitle = this.dailyTitle.replace('\n', '');
      postDailyBoxNew({ title: this.dailyTitle, status: s }).then(() => {
        this.plusStatus = '';
        this.byStatus(parseInt(s));
        this.dailyTitle = '';
      });
    },
    isShowDetail(a) {
      this.traId.pop();
      this.showDailyDetail = false;
      this.showDetail = false;
      console.log(a)
      if (this.traId.length > 0) {
        //还存有id的情况
        if (a === 'dailyBack') {
          let id = this.traId.pop();
          this.$nextTick(() => {
            this.showDailyById(id);
          });
        } else {
          this.getData();
        }
        if (a === 'taskBack') {
          let id = this.traId.pop();
          this.$nextTick(() => {
            this.showById(id);
          });
        } else if (a === 'task') {
          this.getData();
        }
      } else {
        //已经弹光，刷新主界面数据
        this.getData();
      }
    },
    traDaily(a) {
      if (a[1] === 'daily') {
        this.showDailyDetail = false;
        this.$nextTick(() => {
          this.showDailyById(a[0]);
        });
      }
      if (a[1] === 'task') {
        this.showDetail = false;
        this.$nextTick(() => {
          this.showById(a[0]);
        });
      }
    },
    showById(id) {
      this.traId.push(id); //打开详情页是记录id
      this.taskId = id;
      this.showDetail = true;
    },
    showDailyById(id) {
      this.traId.push(id); //打开详情页是记录id
      this.dailyTaskId = id;
      this.showDailyDetail = true;
    },
    search() {
      if (this.keyword === '') {
        this.resId = [];
        return false;
      }
      postDailySearch({
        keyword: this.keyword,
      }).then(res => {
        this.resId = [];
        let data = res.data.data;
        this.resId = data;
      });
    },
    delDailyBox(id, task_id) {
      if (task_id !== '-1') {
        this.$alert('不可在日事清模块删除属于我的任务，请前往任务管理进度删除', '删除异常', {
          confirmButtonText: '确定',
          center: true,
        });
        return false;
      }
      //删除
      this.$confirm('确认要删除该这条任务吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'cancelButtonClass',
        confirmButtonClass: 'confirmButtonClass',
        showClose: false,
        type: 'warning',
        center: true,
      })
        .then(() => {
          deleteDailyBox({ id: id.toString() }).then(() => {
            this.getData();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    drag(e, s, t, i) {
      //开始拖
      e.dataTransfer.setData('id', e.target.id);
      e.dataTransfer.setData('status', s);
      e.dataTransfer.setData('task_id', t);
      e.dataTransfer.setData('index', i);
    },
    allowDrop(e) {
      //允许放
      e.preventDefault();
    },
    drop(e, ts, tind) {
      //四大类---放 tind接受放列表下的index
      e.preventDefault(); //ts是目的列表的状态
      let fs = e.dataTransfer.getData('status'); //来自列表转态
      let id = e.dataTransfer.getData('id');
      let ti = e.dataTransfer.getData('task_id');
      let ind = Number(e.dataTransfer.getData('index'));

      if (ts === fs) {
        //排序
        console.log(tind, ind, ts);
        if (tind != undefined) {
          let arrid = getArrid.call(this, ts, false);
          postDailyStatusRank({
            status: ts,
            // rank: [Number(arr[ind].id), Number(arr[tind].id)],
            rank: arrid,
          }).then(r => {
            console.log(r);
          });
        }
        return false;
      }
      postDailyBoxMod({ id: id, status: ts }).then(() => {
        if (fs !== '0' && ts !== '0') {
          //四大类互相拖的情况
          console.log('fs:' + fs, 'ts:' + ts);
          let arrid = getArrid.call(this, ts);
          // let tmp = Number(ts) - 1;
          // let arrid = this.taskLiRe(this.tasksByLevel[tmp].task).map(v => parseInt(v.id));
          // if (tind == undefined) {
          //   tind = arrid.length;
          // }
          // arrid.splice(tind + 1, 0, parseInt(id));
          // console.log(arrid);
          postDailyStatusRank({
            status: ts,
            rank: arrid,
          }).then(() => {
            this.byStatus(parseInt(fs));
            this.byStatus(parseInt(ts));
          });
          return;
        } else {
          let s = parseInt(fs) === 0 ? parseInt(ts) : parseInt(fs);
          this.byStatus(s);
          if (s == ts) {
            let arrid = getArrid.call(this, ts);
            postDailyStatusRank({
              status: ts,
              rank: arrid,
            }).then(() => {
              this.byStatus(parseInt(fs));
              this.byStatus(parseInt(ts));
            });
          }
          if (ti === '-1') {
            getDailyBoxLis().then(res => {
              //收纳箱列表刷新
              this.dailyTasks = res.data.data;
            });
          } else {
            getDailyMyself().then(res => {
              //我的任务列表刷新
              this.myTask = res.data.data;
            });
          }
        }
      });
      function getArrid(ts, ismod = true) {
        let tmp = Number(ts) - 1;
        if (ismod) {
          let arrid = this.taskLiRe(this.tasksByLevel[tmp].task).map(v => parseInt(v.id));
          if (tind == undefined) {
            tind = arrid.length;
          }
          arrid.splice(tind + 1, 0, parseInt(id));
          console.log(arrid);
          return arrid;
        } else {
          let arr = this.taskLiRe(this.tasksByLevel[tmp].task);
          if (ind > tind) {
            arr.splice(tind, 0, arr[ind]);
            arr.splice(ind + 1, 1);
          } else if (ind < tind) {
            arr.splice(tind + 1, 0, arr[ind]);
            arr.splice(ind, 1);
          }
          this.$nextTick(() => {
            this.$set(this.tasksByLevel[tmp], 'task', this.taskLiRe(arr));
          });
          return this.taskLiRe(arr).map(v => {
            return Number(v.id);
          });
        }
      }
    },
    myTaskDrop(e, ts) {
      //我的任务---放
      e.preventDefault(); //ts是目的列表的状态
      let fs = e.dataTransfer.getData('status'); //来自列表转态
      let id = e.dataTransfer.getData('id');
      let ti = e.dataTransfer.getData('task_id');
      if (ti === '-1') {
        alert('非我负责的任务不能拖入');
        return false;
      }
      if (ts === fs) {
        return false;
      }
      postDailyBoxMod({ id: id, status: ts }).then(() => {
        this.byStatus(parseInt(fs));
        getDailyMyself().then(res => {
          //我的任务列表刷新
          this.myTask = res.data.data;
        });
      });
    },
    dailyDrop(e, ts) {
      //收纳箱---放
      e.preventDefault(); //ts是目的列表的状态
      let fs = e.dataTransfer.getData('status'); //来自列表转态
      let id = e.dataTransfer.getData('id');
      let ti = e.dataTransfer.getData('task_id');
      if (ti !== '-1') {
        alert('非收纳箱的任务不能拖入');
        return false;
      }
      if (ts === fs) {
        return false;
      }
      postDailyBoxMod({ id: id, status: ts }).then(() => {
        this.byStatus(parseInt(fs));
        getDailyBoxLis().then(res => {
          //收纳箱列表刷新
          this.dailyTasks = res.data.data;
        });
      });
    },
    displayDetail(id, task_id) {
      //列表点击事件
      if (task_id === '-1') {
        this.dailyTaskId = id; //赋值
        /*        postDailyBoxInfoLis({ id: id }).then(res => {
          console.log(res);
        });*/
        this.showDailyById(id);
      } else {
        this.showById(task_id);
      }
    },
    infile(id) {
      let fd = new FormData();
      fd.append('clean_id', id);
      postDailyDeal(fd);
    },
    taskListInFile(s) {
      //归档
      let fd = new FormData();
      fd.append('status', s);
      postDailyReturnfile(fd).then(() => {
        this.byStatus(parseInt(s), () => {
          getDailyBoxLis().then(res => {
            //收纳箱
            this.dailyTasks = res.data.data;
          });
          getDailyMyself().then(res => {
            //我的任务
            this.myTask = res.data.data;
          });
          this.isInfileList = '已归档';
        });
      });
    },
    levelSpanHandle(status) {
      //点击状态名span的处理
      this.levelStatus = status;
      this.$nextTick(() => {
        this.$refs[status + 'levmod'][0].focus();
      });
    },
    levelNameMod(status) {
      //修改状态名
      let name = this.levelName;
      let fd = new FormData();
      fd.append('status', status);
      fd.append('name', name);
      postDailyStatusNameMod(fd).then(() => {
        this.levelStatus = '';
        this.levelName = '';
        this.tasksByLevel[parseInt(status) - 1].level = name;
      });
    },
    isdraggable(d) {
      return d !== '3';
    },
  },
  computed: {
    taskLiRe() {
      //倒序列表 后面要求改不倒叙了
      return function (arr) {
        // return arr.slice(0, arr.length).reverse();
        return arr;
      };
    },
    // isdraggable() {
    //   return function (d) {
    //     return d !== '3';
    //   };
    // },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/daily.less';

textarea::-webkit-input-placeholder {
  color: #334681;
}

textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #334681;
}

textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #334681;
}

textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #334681;
}
</style>
